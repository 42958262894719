'use client'

import { cn } from '@paladise/ui/lib/utils'

const PostTag = ({
  label,
  wrapperClassName,
  dotClassName,
  labelClassName,
  isShowDot = true,
}: {
  label: string
  wrapperClassName?: string
  dotClassName?: string
  labelClassName?: string
  isShowDot?: boolean
}) => {
  return (
    <div
      className={cn(
        'flex items-center gap-x-1 pl-[3px] pr-[2px]',
        wrapperClassName,
      )}
      tabIndex={-1}
    >
      {isShowDot && (
        <span className={cn('bg-l-l2 size-0.5 rounded-full', dotClassName)} />
      )}
      <span className={cn('text-body text-l-l2', labelClassName)}>{label}</span>
    </div>
  )
}

export default PostTag
