'use client'

import { cn } from '@paladise/ui/lib/utils'
import type { CustomArrowProps } from 'react-slick'
import {
  SliderArrowDirection,
  SliderArrowDirectionType,
  SliderCategoryType,
  SliderCategory,
} from '../constant'

type SliderArrowProps = CustomArrowProps & {
  direction: SliderArrowDirectionType
  type?: SliderCategoryType
  isShow?: boolean
  wrapperClassName?: string
  spanClassName?: string
}

export const SliderArrow = ({
  onClick,
  direction,
  type = SliderCategory.YOU_MAY_ALSO_LIKE,
  isShow = true,
  wrapperClassName = '',
  spanClassName = '',
}: SliderArrowProps) => {
  if (!isShow) return null

  if (
    type === SliderCategory.YOU_MAY_ALSO_LIKE ||
    type === SliderCategory.RECOMMEND
  ) {
    return (
      <div
        className={cn(
          `bg-label-l3 invisible absolute top-1/2 flex size-10 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full opacity-0 transition-opacity duration-300 group-hover:visible group-hover:opacity-100 ${
            direction === SliderArrowDirection.LEFT ? 'left-4' : 'right-4'
          }`,
          wrapperClassName,
        )}
        onClick={onClick}
      >
        <span
          className={cn(
            'size-[24px] text-[#FAFDFF]',
            direction === SliderArrowDirection.LEFT
              ? 'i-ps-chevron_left'
              : 'i-ps-chevron_right',
            spanClassName,
          )}
        />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'bg-glass-tips absolute bottom-1/2 z-10 flex size-12 cursor-pointer items-center justify-center rounded-full',
        direction === SliderArrowDirection.LEFT
          ? 'min768:left-[-144px] left-[12px]'
          : 'min768:right-[-144px] right-[12px]',
        wrapperClassName,
      )}
      onClick={onClick}
    >
      <span
        className={cn(
          'text-palup-white [--icon-size:24px]',
          direction === SliderArrowDirection.LEFT
            ? 'i-ps-general_arrow_left'
            : 'i-ps-general_arrow_right',
          spanClassName,
        )}
      />
    </div>
  )
}
