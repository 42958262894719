const RecommendNotFoundType = {
  // INSTAGRAM: {
  //   icon: 'i-ps-instagram',
  //   description: 'View full post on Instagram',
  // },
  YOUTUBE: {
    icon: 'i-ps-youtube',
    description: 'View full video on YouTube',
  },
  // TWITTER: {
  //   icon: 'i-ps-twitter',
  //   description: 'View full post on X',
  // },
  // PINTEREST: {
  //   icon: 'i-ps-pinterest-circle',
  //   description: 'View full post on Pinterest',
  // },
  GOOGLE: {
    icon: 'i-ps-google-circle',
    description: 'View full post on Google',
  },
  // GOOGLE_MAP: {
  //   icon: 'i-ps-google-map-circle',
  //   description: 'View full post on Google Map',
  // },
}

const RECOMMEND_MODAL_CARD_DIMENSION = {
  width: 398,
  height: 708,
  ratio: Number((708 / 398).toFixed(2)),
}

export { RECOMMEND_MODAL_CARD_DIMENSION, RecommendNotFoundType }
