'use client'

import { IMetadata } from 'features/discover/type'
import { SliderArrow } from 'features/slider/components/SliderArrow'
import { SliderArrowDirection, SliderCategory } from 'features/slider/constant'
import { useRef } from 'react'
import Slider from 'react-slick'
import { tv } from 'tailwind-variants'
import { RecommendImageCard, RecommendVideoCard } from './RecommendCard'

export const cardStyle = tv({
  slots: {
    cardWrapper: 'relative mr-2 flex-shrink-0 rounded-[20px]',
    cardImage: 'w-full rounded-[20px] object-cover',
    videoCardImage: 'w-full object-cover',
  },
  variants: {
    type: {
      single: {
        cardWrapper: `h-[--recommend-post-card-single-height] w-[--recommend-post-card-single-width]`,
        cardImage: `h-[--recommend-post-card-single-height]`,
        videoCardImage: `h-[--recommend-post-video-single-card-height]`,
      },
      multiple: {
        cardWrapper: `h-[--recommend-post-card-multiple-height] w-[--recommend-post-card-multiple-width]`,
        cardImage: `h-[--recommend-post-card-multiple-height]`,
        videoCardImage: `h-[--recommend-post-video-multiple-card-height]`,
      },
    },
  },
  defaultVariants: {
    type: 'single',
  },
})

const RecommendCardList = ({ card }: { card?: IMetadata[] }) => {
  const sliderRef = useRef<Slider>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerWidth = containerRef.current?.clientWidth || 0

  if (card?.length === 0 || !card) return null

  const hasMultipleCard = card.length > 1
  const style = cardStyle({ type: hasMultipleCard ? 'multiple' : 'single' })
  const isShowSliderArrow =
    hasMultipleCard && card.length * 280 > containerWidth

  return (
    <div className="group relative ml-[60px]" ref={containerRef}>
      <Slider
        arrows={false}
        variableWidth
        swipeToSlide
        ref={sliderRef}
        speed={500}
        infinite={hasMultipleCard}
        className={
          hasMultipleCard
            ? 'h-[--recommend-post-card-multiple-height]'
            : 'h-[--recommend-post-card-single-height]'
        }
      >
        {card.map((item, index) => {
          if (item.source === 'youtube') {
            return (
              <RecommendVideoCard
                key={`${item.content_id}-${index}`}
                metadata={item}
                cardWrapperStyle={`${style.cardWrapper()}`}
                cardImageStyle={style.videoCardImage()}
                hasMultipleCard={hasMultipleCard}
              />
            )
          }
          return (
            <RecommendImageCard
              key={`${item.content_id}-${index}`}
              metadata={item}
              cardWrapperStyle={`${style.cardWrapper()}`}
              cardImageStyle={style.cardImage()}
              hasMultipleCard={hasMultipleCard}
            />
          )
        })}
      </Slider>
      <SliderArrow
        isShow={isShowSliderArrow}
        direction={SliderArrowDirection.LEFT}
        type={SliderCategory.RECOMMEND}
        onClick={event => {
          event.stopPropagation()
          sliderRef.current?.slickPrev()
        }}
      />
      <SliderArrow
        isShow={isShowSliderArrow}
        direction={SliderArrowDirection.RIGHT}
        type={SliderCategory.RECOMMEND}
        onClick={event => {
          event.stopPropagation()
          sliderRef.current?.slickNext()
        }}
      />
    </div>
  )
}

export default RecommendCardList
