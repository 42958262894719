'use client'

const checkImageUrl = async (url: string): Promise<{ isValid: boolean }> => {
  return new Promise(resolve => {
    try {
      const img = new Image()

      img.onload = () => {
        resolve({
          isValid: true,
        })
      }

      img.onerror = () => {
        resolve({
          isValid: false,
        })
      }

      img.src = url
    } catch {
      resolve({
        isValid: false,
      })
    }
  })
}

export { checkImageUrl }
