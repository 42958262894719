import { useCallback, useEffect, useState } from 'react'
import { checkImageUrl } from 'utils/checkImageUrl'

export const useImageValidation = (imageUrl: string) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isValid, setIsValid] = useState(true)

  const validateImage = useCallback(async () => {
    try {
      setIsLoading(true)
      const { isValid } = await checkImageUrl(imageUrl)
      setIsValid(isValid)
    } catch (error) {
      setIsValid(false)
    } finally {
      setIsLoading(false)
    }
  }, [imageUrl])

  useEffect(() => {
    validateImage()
  }, [validateImage])

  return { isLoading, isValid }
}
