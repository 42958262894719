'use client'

import { IMetadataAvatar, MarketplacePlacement } from 'features/discover/type'
import React from 'react'

const FeedContext = React.createContext<
  | (IMetadataAvatar & {
      placement?: keyof typeof MarketplacePlacement
      onBoostTrackerClick: () => void
    })
  | null
>(null)

export const FeedProvider = ({
  value,
  children,
}: {
  value: IMetadataAvatar & {
    placement?: keyof typeof MarketplacePlacement
    onBoostTrackerClick: () => void
  }
  children: React.ReactNode
}) => <FeedContext.Provider value={value}>{children}</FeedContext.Provider>

export const useFeed = () => {
  const context = React.useContext(FeedContext)
  if (!context) {
    throw new Error('useFeed must be used within a FeedProvider')
  }
  return context
}
