'use client'

import { cn } from '@paladise/ui/lib/utils'

const TOTAL_STAR_AMOUNT = 5

type RatingStarProps = {
  rating: number
  className?: string
}

const RatingStar = ({ rating, className }: RatingStarProps) => {
  const fullStar = Math.floor(rating)
  const emptyStar = TOTAL_STAR_AMOUNT - fullStar

  return (
    <div className={cn('flex', className)}>
      {Array.from({ length: fullStar }).map((_, i) => (
        <span
          key={`full-${i}`}
          className="i-ps-star_f text-palup-brand-yellow size-[16px]"
        />
      ))}
      {Array.from({ length: emptyStar }).map((_, i) => (
        <span
          key={`empty-${i}`}
          className="i-ps-star_f text-label-still-l3 size-[16px]"
        />
      ))}
    </div>
  )
}

export default RatingStar
