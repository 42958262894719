'use client'

import { IMetadata } from 'features/discover/type'
import { useFeed } from '../context/feed'
import { useImageValidation } from '../hooks/useImageValidation'
import { RecommendFallback, RecommendSkeleton } from './RecommendCard'
import { cardStyle } from './RecommendCardList'

type Props = {
  metadata: IMetadata
  wrapperClassName?: string
  renderContent: JSX.Element
}

export const PostContent = ({
  metadata,
  wrapperClassName = '',
  renderContent,
}: Props) => {
  const { post } = useFeed()
  const style = cardStyle({
    type: (post?.metadata.length || 0) > 1 ? 'multiple' : 'single',
  })
  const { isLoading, isValid } = useImageValidation(metadata.image)

  if (isLoading) {
    return (
      <RecommendSkeleton className={wrapperClassName || style.cardWrapper()} />
    )
  }

  if (!isValid) {
    return (
      <RecommendFallback
        metadata={metadata}
        className={wrapperClassName || style.cardWrapper()}
      />
    )
  }

  return renderContent
}
