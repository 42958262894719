export const SliderCategory = {
  RECOMMEND: 'recommend',
  PREVIEW: 'preview',
  YOU_MAY_ALSO_LIKE: 'you-may-also-like',
} as const

export const SliderArrowDirection = {
  LEFT: 'left',
  RIGHT: 'right',
} as const

export type SliderCategoryType =
  (typeof SliderCategory)[keyof typeof SliderCategory]
export type SliderArrowDirectionType =
  (typeof SliderArrowDirection)[keyof typeof SliderArrowDirection]
