'use client'

import { Button } from '@paladise/ui/components/ui/button'

import { Dialog, DialogTrigger } from '@paladise/ui/components/ui/dialog'
import { cn } from '@paladise/ui/lib/utils'
import { IMetadata } from 'features/discover/type'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { RecommendNotFoundType } from '../constant'
import { useFeed } from '../context/feed'
import { PostContent } from './PostContent'
import { RampGradient } from './RampGradient'
import RatingStar from './RatingStar'
import RecommendModal from './RecommendModal'

type Props = {
  cardWrapperStyle?: string
  cardImageStyle?: string
  metadata: IMetadata
  hasMultipleCard: boolean
}

const RecommendRating = ({ rating }: { rating: IMetadata['rating'] }) => {
  if (Math.floor(rating) === 0) return null

  return (
    <div className="flex items-center gap-1">
      <p className="text-caption text-label-still-l2">{rating}</p>
      <RatingStar rating={rating} />
    </div>
  )
}

const RecommendInfoDetail = ({
  source,
  published_years,
  author,
  link,
  channel_id,
}: {
  source: IMetadata['source']
  published_years: IMetadata['published_years']
  author: IMetadata['author']
  link?: IMetadata['link']
  channel_id?: IMetadata['channel_id']
}) => {
  const capitalizeFirstLetter = (string: string) => {
    return string
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const renderContent = () => {
    switch (source) {
      case 'google_maps':
        return `©${published_years} ${capitalizeFirstLetter(source)} ${author}`
      case 'youtube':
        return `©${published_years} ${capitalizeFirstLetter(source)} ${channel_id || ''}`
      default:
        return link || ''
    }
  }

  return (
    <p className="text-label-still-l2 text-caption mt-auto line-clamp-1">
      {renderContent()}
    </p>
  )
}

const RecommendImageCard = ({
  metadata,
  cardWrapperStyle = '',
  cardImageStyle = '',
  hasMultipleCard,
}: Props) => {
  const {
    source,
    published_years,
    author,
    image,
    title,
    desc,
    price,
    rating,
    link,
  } = metadata
  const { onBoostTrackerClick } = useFeed()

  return (
    <Dialog>
      <DialogTrigger
        asChild
        onClick={e => {
          e.stopPropagation()
          onBoostTrackerClick()
        }}
      >
        <div className="cursor-pointer">
          <PostContent
            metadata={metadata}
            renderContent={
              <div className={`${cardWrapperStyle}`}>
                <RampGradient
                  direction="top"
                  className="h-[48px] rounded-t-[20px]"
                />
                <img src={image} alt={title} className={cardImageStyle} />
                <div className="absolute bottom-0 left-0 z-10 w-full px-4 pb-4 pt-[10px]">
                  <div className="flex flex-col gap-0.5 text-left">
                    <h2 className="text-callout text-label-still-l1 line-clamp-1">
                      {title}
                    </h2>
                    <RecommendRating rating={rating} />
                    <p className="text-caption text-label-still-l2">{price}</p>
                    <p className="text-body text-gray-gray4 line-clamp-3">
                      {desc}
                    </p>
                    <RecommendInfoDetail
                      source={source}
                      published_years={published_years}
                      author={author}
                      link={link}
                    />
                  </div>
                  <RampGradient
                    direction="bottom"
                    className="z-[-1] h-full rounded-b-[20px]"
                  />
                </div>
              </div>
            }
            wrapperClassName={cardWrapperStyle}
          />
        </div>
      </DialogTrigger>
      <RecommendModal showArrow={hasMultipleCard} image={image} />
    </Dialog>
  )
}

const RecommendVideoCard = ({
  metadata,
  cardImageStyle = '',
  cardWrapperStyle = '',
  hasMultipleCard,
}: Props) => {
  const t = useTranslations()
  const { source, published_years, author, image, title, channel_id } = metadata
  const { onBoostTrackerClick } = useFeed()

  return (
    <Dialog>
      <DialogTrigger
        asChild
        onClick={e => {
          e.stopPropagation()
          onBoostTrackerClick()
        }}
      >
        <div className="cursor-pointer">
          <PostContent
            metadata={metadata}
            renderContent={
              <div
                className={`flex items-center bg-[#0c1212] ${cardWrapperStyle}`}
              >
                <img src={image} alt={title} className={`${cardImageStyle}`} />
                <div className="absolute bottom-0 left-0 flex w-full items-end px-4 py-4 pt-10">
                  <div className="w-full text-left">
                    <Button className="bg-overlay-still text-palup-white h-10 w-[99px] rounded-[50px] px-[13px] py-[11px]">
                      <span className="i-ps-play_f text-palup-white [--icon-size:18px]" />
                      <span className="text-palup-white text-subtitle pl-1 pr-[3px]">
                        {t('watch')}
                      </span>
                    </Button>
                    <RecommendModal showArrow={hasMultipleCard} image={image} />
                    <RecommendInfoDetail
                      source={source}
                      published_years={published_years}
                      author={author}
                      channel_id={channel_id}
                    />
                  </div>
                </div>
              </div>
            }
            wrapperClassName={cardWrapperStyle}
          />
        </div>
      </DialogTrigger>
      <RecommendModal showArrow={hasMultipleCard} image={image} />
    </Dialog>
  )
}

const RecommendFallback = ({
  metadata,
  className,
}: {
  metadata: IMetadata
  className?: string
}) => {
  const t = useTranslations()
  const { link, source } = metadata

  const type = source.toUpperCase() as keyof typeof RecommendNotFoundType

  return (
    <Link href={link} target="_blank">
      <article
        className={cn(
          'bg-background-2nd flex h-[407px] w-[291px] flex-col items-center justify-center rounded-[20px] px-4',
          className,
        )}
      >
        <span
          className={`${RecommendNotFoundType?.[type]?.icon || 'i-ps-google-circle'} text-label-l1 size-[40px]`}
          aria-hidden="true"
        />
        <p className="text-label-l1 text-callout">
          {RecommendNotFoundType?.[type]?.description ||
            t('view_full_video_on_google')}
        </p>
      </article>
    </Link>
  )
}

const RecommendSkeleton = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        'bg-background-2nd h-[--recommend-post-card-single-height] w-[--recommend-post-card-single-width] flex-shrink-0 animate-pulse rounded-[20px]',
        className,
      )}
    />
  )
}

export {
  RecommendFallback,
  RecommendImageCard,
  RecommendSkeleton,
  RecommendVideoCard,
}
