'use client'

import Slider from 'react-slick'

import { DialogContent, DialogTitle } from '@paladise/ui/components/ui/dialog'
import { SliderArrow } from 'features/slider/components/SliderArrow'
import { SliderArrowDirection } from 'features/slider/constant'
import { useEffect, useState } from 'react'
import { RECOMMEND_MODAL_CARD_DIMENSION } from '../constant'
import { useFeed } from '../context/feed'
import { FullImageCard, FullVideoCard } from './FullCard'

type RecommendModalProps = {
  showArrow: boolean
  image: string
}

const RecommendModal = ({ showArrow, image }: RecommendModalProps) => {
  const { post } = useFeed()

  const [currentSlide, setCurrentSlide] = useState(
    post?.metadata.findIndex(item => item.image === image) || 0,
  )
  const [dimension, setDimension] = useState({
    width: RECOMMEND_MODAL_CARD_DIMENSION.width,
    height: RECOMMEND_MODAL_CARD_DIMENSION.height,
  })
  const [activeVideoIndex, setActiveVideoIndex] = useState<number | null>(null)

  const handleBeforeChange = (currentSlide: number, nextSlide: number) => {
    setActiveVideoIndex(null)
    setCurrentSlide(nextSlide)
  }

  const handlePlay = (index: number) => {
    setActiveVideoIndex(index)
    setCurrentSlide(index)
  }

  const handlePause = (index: number) => {
    if (activeVideoIndex === index) {
      setActiveVideoIndex(null)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > RECOMMEND_MODAL_CARD_DIMENSION.width) return

      const width = Math.max(0, window.innerWidth - 32)

      const height = width * RECOMMEND_MODAL_CARD_DIMENSION.ratio

      setDimension({
        width,
        height,
      })
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', () => {})
  }, [])

  return (
    <DialogContent
      showClose
      className="flex h-dvh w-full max-w-[--max-app-size] items-center justify-center rounded-none bg-black p-4"
      closeClassName="text-label-still-l1"
      closeIconSize="min768:[--icon-size:32px] [--icon-size:24px]"
      overlayClassName="bg-black"
    >
      <DialogTitle className="sr-only hidden" />
      <article
        style={{
          width: dimension.width,
          height: dimension.height,
        }}
        className="relative mx-auto"
      >
        <Slider
          slidesToShow={1}
          slidesToScroll={1}
          dots={false}
          arrows={showArrow}
          autoplay={false}
          infinite={(post?.metadata?.length || 0) > 1}
          nextArrow={<SliderArrow direction={SliderArrowDirection.RIGHT} />}
          prevArrow={<SliderArrow direction={SliderArrowDirection.LEFT} />}
          initialSlide={currentSlide}
          className="w-full"
          beforeChange={handleBeforeChange}
        >
          {post?.metadata.map((item, index) => {
            if (item.source === 'youtube') {
              return (
                <FullVideoCard
                  key={`${item.link}-${index}`}
                  isPlaying={activeVideoIndex === index}
                  onPlaying={() => handlePlay(index)}
                  onPause={() => handlePause(index)}
                  dimension={dimension}
                  {...item}
                />
              )
            }
            return (
              <FullImageCard
                key={`${item.link}-${index}`}
                dimension={dimension}
                {...item}
              />
            )
          })}
        </Slider>
      </article>
    </DialogContent>
  )
}

export default RecommendModal
