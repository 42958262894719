import { FilterParamsType, IAvatarPost } from 'features/avatarProfile/type'
import {
  AvatarMetadata,
  type Avatar,
  type ImagePromptResponse,
} from 'features/bot/type'
import {
  IDiscoverTop,
  IMetadataAvatar,
  MarketplacePlacement,
} from 'features/discover/type'
import { type Fetch } from 'lib/fetch/type'
import { IRespV2, type IResp } from 'type/common'
import { generateParams } from 'utils/generate-params'

export const getGoldenAvatars = async (
  fetcher: Fetch,
  query?: string,
): Promise<IResp<{ avatars: Avatar[] }>> => {
  const res = await fetcher(
    fetcher.API.ASGARD + '/web/v1/avatar/golden' + (query ? `?${query}` : ''),
    {
      method: 'GET',
    },
  )
  return res
}

export const getAvatars = async (
  fetcher: Fetch,
  query?: string,
): Promise<IResp<{ avatars: Avatar[] }>> => {
  const res = await fetcher(
    fetcher.API.ASGARD + '/web/v1/avatar/list' + (query ? `?${query}` : ''),
    {
      method: 'GET',
    },
  )
  return res
}

export const getFavoriteAvatars = async (
  fetcher: Fetch,
  query?: string,
): Promise<IResp<{ avatars: Avatar[] }>> => {
  const res = await fetcher(
    fetcher.API.ASGARD +
      '/web/v1/avatar/favorite_list' +
      (query ? `?${query}` : ''),
    {
      method: 'GET',
    },
  )
  return res
}

export const getAvatar = async (
  fetcher: Fetch,
  id: string,
): Promise<IResp<Avatar>> => {
  const res = await fetcher(fetcher.API.ASGARD + `/web/v1/avatar/${id}`, {
    method: 'GET',
  })
  return res
}

export const getAvatarV2 = async (
  fetcher: Fetch,
  id: string,
): Promise<IRespV2<IMetadataAvatar | null>> => {
  const res = await fetcher(fetcher.API.GW + `/v1/avatars/${id}`, {
    method: 'GET',
  })
  return res
}

export const getRelatedAvatars = async (
  fetcher: Fetch,
  avatar_id: string,
  query?: string,
): Promise<IResp<{ avatars: Avatar[] }>> => {
  const res = await fetcher(
    fetcher.API.ASGARD +
      `/web/v1/avatar/${avatar_id}/related` +
      (query ? `?${query}` : ''),
    {
      method: 'GET',
    },
  )
  return res
}

export const searchAvatar = async (
  fetcher: Fetch,
  query?: string,
): Promise<IResp<{ avatars: Avatar[] }>> => {
  const res = await fetcher(
    fetcher.API.ASGARD + '/web/v1/avatar/search' + (query ? `?${query}` : ''),
    {
      method: 'GET',
    },
  )
  return res
}

export const detectImagePrompt = async (
  fetcher: Fetch,
  body: {
    image_url: string
  },
): Promise<IResp<ImagePromptResponse>> => {
  const res = await fetcher(
    fetcher.API.ASGARD + '/web/v1/detect/image_prompt',
    {
      method: 'POST',
      body,
    },
  )

  return res
}

export const createAvatar = async (
  fetcher: Fetch,
  body: {
    is_random: boolean
    source_type: number

    is_enterprise?: boolean
    category?: string
    purchase_id?: string // subscription id
  },
): Promise<IResp<Avatar>> => {
  const res = await fetcher(fetcher.API.ASGARD + '/web/v1/avatar/init', {
    method: 'POST',
    body,
  })

  return res
}

export const updateAvatar = async (
  fetcher: Fetch,
  id: string,
  body: Partial<Avatar>,
): Promise<IResp<Avatar>> => {
  const res = await fetcher(
    fetcher.API.ASGARD + `/web/v1/avatar/record/${id}`,
    {
      method: 'PATCH',
      body,
    },
  )

  return res
}

export const generatePicture = async (
  fetcher: Fetch,
  avatarId: string,
  body: {
    gender: string
    appearance: string
    source_image: string
  },
): Promise<
  IResp<{
    result: {
      image_url: string
      type: string
      appearance: string
    }[]
  }>
> => {
  const res = await fetcher(
    fetcher.API.ASGARD + `/web/v1/avatar/repaint/${avatarId}`,
    {
      method: 'PATCH',
      body: body,
    },
  )

  return res
}

export const getConnectionInfo = async (
  fetcher: Fetch,
  body: {
    gender: string
    image_url: string
    language: string
    voice: string
    template_id: string
    room_id: string
  },
): Promise<IResp<{ id: string; token: string }>> => {
  const res = await fetcher(fetcher.API.MEDIA + '/web/v1/stream/', {
    method: 'POST',
    body,
  })

  return res
}

export const subscribeAvatar = async (
  fetcher: Fetch,
  body: {
    avatar_id: string
    purchase_id: string
  },
): Promise<IResp<string>> => {
  const res = await fetcher(fetcher.API.ASGARD + `/web/v1/avatar/subscribe`, {
    method: 'POST',
    body,
  })

  return res
}

export const updateAvatarFavorite = async (
  fetcher: Fetch,
  body: {
    avatar_id: string
    is_fav: boolean
  },
): Promise<IResp<string>> => {
  const res = await fetcher(fetcher.API.ASGARD + `/web/v1/avatar/favorite`, {
    method: 'POST',
    body,
  })

  return res
}

export const checkFreeTrial = async (
  fetcher: Fetch,
): Promise<IRespV2<{ available: boolean }>> => {
  const res = await fetcher(
    fetcher.API.ASGARD + `/web/v1/avatar/free_trial_available`,
    {
      method: 'GET',
    },
  )

  return res
}

export const updateAvatarKnowledge = async (
  fetcher: Fetch,
  avatarId: string,
  body: {
    knowledge: {
      file_name: string
      url: string
    }[]
  },
): Promise<IRespV2<string>> => {
  const res = await fetcher(
    fetcher.API.ASGARD + `/web/v1/avatar/record/${avatarId}/uploaded`,
    {
      method: 'POST',
      body,
    },
  )

  return res
}

export const getAvatarSEO = async (
  fetcher: Fetch,
  id: string,
  lang?: string,
): Promise<IRespV2<AvatarMetadata>> => {
  const res = await fetcher(
    fetcher.API.ASGARD + `/web/v1/avatar/seo/${id}?lang=${lang || 'en'}`,
    {
      method: 'GET',
    },
  )

  return res
}

export const getAvatarIdByName = async (
  fetcher: Fetch,
  name: string,
): Promise<IRespV2<{ avatar_id: string }>> => {
  const res = await fetcher(
    fetcher.API.ASGARD + `/web/v1/avatar/seo/get_id_by_name?name=${name}`,
    {
      method: 'GET',
    },
  )
  return res
}

export const detectCelebrity = async (
  fetcher: Fetch,
  body: {
    image: string
  },
): Promise<
  IRespV2<{
    has_celebrities: boolean
    celebrities: string[]
  }>
> => {
  const res = await fetcher(
    fetcher.API.ASGARD + '/web/v1/avatar/util/recognize_celebrities',
    {
      method: 'POST',
      body,
    },
  )

  return res
}

export const getDiscoverTopAvatar = async (
  fetcher: Fetch,
  is_refresh: boolean,
  display: {
    display_banner?: boolean
    display_suggest?: boolean
  },
): Promise<IDiscoverTop | null> => {
  const { display_banner, display_suggest } = display
  try {
    const params = generateParams({
      display_banner: `${display_banner || false}`,
      display_suggest: `${display_suggest || false}`,
      is_refresh: `${is_refresh}`,
      banner_placement: MarketplacePlacement.for_you_banner_web,
      suggest_placement: MarketplacePlacement.for_you_recommend_web,
      feed_placement: MarketplacePlacement.for_you_recommend_web,
    })
    const res: IRespV2<IDiscoverTop> = await fetcher(
      fetcher.API.GW + `/v1/avatars/discover_top?${params}`,
      {
        method: 'GET',
      },
    )
    return {
      banner: res.data.banner || [],
      feed: res.data.feed || [],
      suggest: res.data.suggest || [],
    }
  } catch (error) {
    return {
      banner: [],
      feed: [],
      suggest: [],
    }
  }
}

export const getDiscoverTabAvatar = async (
  fetcher: Fetch,
  data: {
    is_refresh: boolean
    page_size: number
    topic: string
    placement: keyof typeof MarketplacePlacement
  },
): Promise<IMetadataAvatar[] | null> => {
  try {
    const params = generateParams({
      ...data,
      is_refresh: `${data.is_refresh}`,
    })
    const res: IResp<IMetadataAvatar[]> = await fetcher(
      fetcher.API.GW + `/v1/avatars/discover_tab?${params}`,
      {
        method: 'GET',
      },
    )
    return res.data
  } catch (error) {
    return null
  }
}

export const getAlsoLikeAvatar = async (
  fetcher: Fetch,
  is_refresh: boolean,
  page_size: number,
): Promise<IMetadataAvatar[] | null> => {
  try {
    const params = generateParams({
      is_refresh: `${is_refresh}`,
      placement: MarketplacePlacement.for_you_favorite_web,
      page_size,
    })
    const res: IRespV2<IMetadataAvatar[]> = await fetcher(
      fetcher.API.GW + `/v1/avatars/also_like?${params}`,
      {
        method: 'GET',
      },
    )
    return res.data
  } catch (error) {
    return null
  }
}

export const getRecommendAvatars = async (
  fetcher: Fetch,
  is_refresh: boolean,
  page_size: number,
): Promise<IMetadataAvatar[] | null> => {
  try {
    const params = generateParams({
      is_refresh: `${is_refresh}`,
      placement: MarketplacePlacement.feed_web,
      page_size,
    })
    const res: IRespV2<{ avatars: IMetadataAvatar[] }> = await fetcher(
      fetcher.API.ASGARD + `/web/v1/avatar/recommend?${params}`,
      {
        method: 'GET',
      },
    )
    return res.data.avatars
  } catch (error) {
    return null
  }
}

export const getMarketPlaceAvatars = async (
  fetcher: Fetch,
  data: {
    is_refresh: boolean
    page_size: number
    placement: keyof typeof MarketplacePlacement
  },
): Promise<IMetadataAvatar[] | null> => {
  try {
    const params = generateParams({
      ...data,
      is_refresh: `${data.is_refresh}`,
      filter: 'hot',
    })
    const res: IResp<{ avatars: IMetadataAvatar[] }> = await fetcher(
      fetcher.API.ASGARD + `/web/v1/avatar/marketplace?${params}`,
      {
        method: 'GET',
      },
    )
    return res.data.avatars
  } catch (error) {
    return null
  }
}

export const getAvatarPosts = async ({
  fetcher,
  avatarId,
  page,
  filter,
}: {
  fetcher: Fetch
  avatarId: string
  page: number
  filter: FilterParamsType
}): Promise<IRespV2<IAvatarPost>> => {
  const params = generateParams({
    filter,
    pages: page,
  })
  const res = await fetcher(
    fetcher.API.GW + `/v1/avatars/${avatarId}/posts?${params}`,
    {
      method: 'GET',
    },
  )
  return res
}

export const getAvatarsByKeyword = async (
  fetcher: Fetch,
  query?: string,
): Promise<IRespV2<{ avatars: IMetadataAvatar[] }>> => {
  const params = query ? `?${query}` : ''
  const res = await fetcher(fetcher.API.GW + `/v1/avatars/search${params}`, {
    method: 'GET',
  })
  return res
}

export const getRelatedBrandAvatars = async ({
  fetcher,
  is_refresh,
  page_size,
  avatar_id,
}: {
  fetcher: Fetch
  is_refresh: boolean
  page_size: number
  avatar_id: string
}): Promise<IMetadataAvatar[] | null> => {
  try {
    const params = generateParams({
      is_refresh: `${is_refresh}`,
      page_size,
      avatar_id,
    })
    const res: IRespV2<{ avatars: IMetadataAvatar[] }> = await fetcher(
      fetcher.API.ASGARD +
        `/web/v1/avatar/${avatar_id}/brand_related?${params}`,
      {
        method: 'GET',
      },
    )
    return res.data.avatars
  } catch (error) {
    return null
  }
}
