export const MarketplacePlacement = {
  feed_web: 'feed_web',
  for_you_banner_web: 'for_you_banner_web',
  for_you_favorite_web: 'for_you_favorite_web',
  for_you_recommend_web: 'for_you_recommend_web',
  trending_web: 'trending_web',
  entertainment_web: 'entertainment_web',
  sports_web: 'sports_web',
  news_web: 'news_web',
} as const

export const DiscoverTabTopic = {
  entertainment: 'entertainment',
  sports: 'sports',
  news: 'news',
} as const

export interface IMetadata {
  link: string
  source: string
  image: string
  content_id: string
  title: string
  icon: string
  author: string
  desc: string
  published_years: string
  rating: number
  reviewed_years: string
  price: string
  channel_id: string
}

export interface IPost {
  id: string
  source_id: string
  avatar_id: string
  type: string
  category: string
  content: string
  memory_content: string
  language_code: string
  metadata: IMetadata[]
  created_at: string
  updated_at: string
  published_at: string
  deleted_at: null
}

export interface IBoost_info {
  impression_token: Partial<Record<keyof typeof MarketplacePlacement, string>>
  click_token: Partial<Record<keyof typeof MarketplacePlacement, string>>
}

export interface IMetadataAvatar {
  id: string
  name: string
  gender: string
  appearance: null
  appearance_prompt: null
  appearance_hint: null
  voice: null
  voice_tone_id: null
  user_id: string
  personality_id: null
  profession_type: string
  category: string
  bio: string
  cultural_roots: null
  hobbies: null
  favorite_food: null
  family: null
  profession: null
  knowledge: null
  knowledge_urls: null
  image_url: string
  videos: any[]
  post: IPost | null
  multi_head: null
  recommend_percent: null
  bot_schedule_in_min: null
  age: null
  race: null
  background: null
  interest: null
  need_appearance: null
  need_bio: null
  need_background: null
  session_id: null
  stream_id: null
  public_status: number
  source_type: null
  available_quota: null
  used_quota: null
  is_expire: boolean
  is_cancel: boolean
  is_active: boolean
  status: null
  template_id: null
  head_shot_url: string
  head_shot_watermark_url: null
  lang: null
  is_favorite: boolean
  current_steps: null
  total_steps: null
  is_reviewing: boolean
  is_violated: boolean
  report_violated_fields: null
  is_publish: boolean
  talk_turn_total: number | null
  talk_turn_7d_pr: number | null
  talk_turn_30d_count: number | null
  pool: string
  is_free_trial: null
  is_official: boolean
  is_background_generated: null
  relevant_score: null
  is_marketplace_replace: boolean
  available_voice_tones: null
  company_id: null | string
  company_info: null
  group_ids: null
  is_sponsored: boolean
  mission_enable: null
  mission_goal: null
  mission_link: null
  mission_phone: null
  mission_email: null
  mission_description: null
  auto_post_enable: null
  twitter_id: null
  youtube_ids: string[] | null
  instagram_id: null
  pinterest_id: null
  subscription_plan: string | null
  user_last_update_time: null
  update_time: string
  expire_time: null
  create_time: null
  boost_info: IBoost_info | null
}

export interface IDiscoverTop {
  banner: IMetadataAvatar[]
  feed: IMetadataAvatar[]
  suggest: IMetadataAvatar[]
}
