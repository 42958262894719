'use client'

import { NO_PHOTO_PATH } from '@paladise/config/constants'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@paladise/ui/components/ui/avatar'
import { IMetadata } from 'features/discover/type'
import { useTranslations } from 'next-intl'
import ReactPlayer from 'react-player/youtube'
import { tv } from 'tailwind-variants'
import { useFeed } from '../context/feed'
import { PostContent } from './PostContent'
import PostTag from './PostTag'
import { RampGradient } from './RampGradient'
import { RecommendSkeleton } from './RecommendCard'

const style = tv({
  slots: {
    wrapper:
      'relative mx-auto h-[var(--card-height)] w-[var(--card-width)] overflow-hidden rounded-[20px]',
    image:
      'h-[var(--card-height)] w-[var(--card-width)] rounded-[20px] object-cover object-center',
  },
})

const CardInfo = ({ url }: { url: string }) => {
  const t = useTranslations()
  const { head_shot_url, name, profession_type, is_sponsored, post } = useFeed()
  const content = post?.metadata.find(item => item.link === url)?.desc || ''

  return (
    <article className="absolute bottom-0 left-0 right-0 flex h-fit flex-col px-3 py-4">
      <div className="mb-3 flex gap-2">
        <Avatar className="size-9">
          <AvatarImage src={head_shot_url} alt={name} />
          <AvatarFallback>
            <img
              src={NO_PHOTO_PATH}
              alt="headshot fallback"
              className="size-9"
            />
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-1 flex-col py-[1px]">
          <h2 className="text-body text-label-still-l1">{name}</h2>
          <div className="text-label-still-l2 flex items-center">
            {profession_type && (
              <PostTag
                isShowDot={false}
                label={profession_type}
                wrapperClassName="p-0"
                dotClassName="bg-label-still-l2"
                labelClassName="text-caption text-label-still-l2"
              />
            )}
            {is_sponsored && (
              <PostTag
                label={t('sponsored')}
                labelClassName="text-label-still-l2"
              />
            )}
          </div>
          <RampGradient
            direction="bottom"
            className="z-[-1] h-full w-full rounded-b-[20px] bg-[--gradient-suggested-avatar]"
          />
        </div>
      </div>
      <p className="text-caption text-label-still-l2 line-clamp-2">{content}</p>
    </article>
  )
}

type FullImageCardProps = IMetadata & {
  dimension: {
    width: number
    height: number
  }
}

const FullImageCard = ({
  image,
  link,
  dimension,
  ...rest
}: FullImageCardProps) => {
  const { wrapper, image: imageStyle } = style()

  return (
    <div
      style={{
        '--card-width': `${dimension.width}px`,
        '--card-height': `${dimension.height}px`,
      }}
    >
      <PostContent
        metadata={{
          image,
          link,
          ...rest,
        }}
        wrapperClassName={`${wrapper()} flex-col justify-center`}
        renderContent={
          <div className={wrapper()}>
            <img src={image} alt="full image" className={imageStyle()} />
            <CardInfo url={link} />
          </div>
        }
      />
    </div>
  )
}

type FullVideoCardProps = IMetadata & {
  isPlaying: boolean
  onPlaying: () => void
  onPause: () => void
  dimension: {
    width: number
    height: number
  }
}

const FullVideoCard = ({
  image,
  link,
  isPlaying,
  onPlaying,
  onPause,
  dimension,
  ...rest
}: FullVideoCardProps) => {
  const { wrapper, image: imageStyle } = style()

  return (
    <div
      style={{
        '--card-width': `${dimension.width}px`,
        '--card-height': `${dimension.height}px`,
      }}
    >
      <PostContent
        metadata={{
          image,
          link,
          ...rest,
        }}
        wrapperClassName={`${wrapper()} flex-col justify-center`}
        renderContent={
          <div className={wrapper()}>
            <ReactPlayer
              url={link}
              className={imageStyle()}
              width={dimension.width}
              height={dimension.height}
              fallback={
                <RecommendSkeleton className="h-[var(--card-height)] w-[var(--card-width)]" />
              }
              playing={isPlaying}
              onPlay={onPlaying}
              onPause={onPause}
            />
            <CardInfo url={link} />
          </div>
        }
      />
    </div>
  )
}

export { FullImageCard, FullVideoCard }
