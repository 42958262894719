import { cn } from '@paladise/ui/lib/utils'

export const RampGradient = ({
  className,
  direction = 'bottom',
}: {
  className?: string
  direction?: 'top' | 'bottom'
}) => {
  return (
    <div
      className={cn(
        'absolute left-0 right-0 w-full',
        direction === 'top'
          ? 'top-0 bg-[linear-gradient(to_bottom,rgba(0,0,0,0.4)_0%,rgba(0,0,0,0)_40%)]'
          : 'bottom-0 bg-[linear-gradient(to_top,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0)_100%)]',
        className,
      )}
    />
  )
}
